<template>
  <form v-if="(canWrite && validOrgId) || isCreateForm" class="relative">
    <FixedActionBar
      v-if="canWrite"
      button-type="submit"
      :is-loading="isSubmitting"
      :show-create="isCreateForm"
      :show-update="!isCreateForm"
      class="mb-6"
      @delete="confirmDeleteOrganization(orgId)"
      @create="
        async () =>
          (await v$.$validate()) ? createOrganizationThenRedisplay(form) : null
      "
      @update="
        async () =>
          (await v$.$validate())
            ? updateOrganizationThenRedisplay(orgId, form, paymentForm)
            : null
      "
    >
      <OrganizationImportTfaDialog
        v-if="isCopyAllowed && form.name"
        :org-name="form.name"
        :org-id="orgId"
      />
    </FixedActionBar>

    <AppCard class="mb-6 animate-fadein animate-duration-1000">
      <div class="flex">
        <div v-if="!isCreateForm" class="">
          <div class="col flex flex-col gap-2">
            <SingleImageUploaderAndDisplay
              label="Logo"
              class="mr-20"
              :disclaimer-text="`Allowed file types: ${allowedImageFileExtensionsByKey}`"
              :max-width="160"
              :image-data="logoImageData?.file"
              :thumbnail-width="140"
              :organization-id="orgId"
              :allow-delete-button="true"
              @upload-complete="uploadLogoImageComplete"
              @upload-failed="uploadImageFailed"
              @request-delete="uploadLogoImageDelete"
            />
          </div>
        </div>

        <div class="flex flex-col grow gap-2">
          <div class="col flex flex-col gap-2">
            <label for="name">Name</label>
            <InputText
              id="name"
              v-model="form.name"
              type="text"
              placeholder="Name"
              @blur="v$.name.$touch"
            />
            <small v-if="v$ErrorMessage(v$.name.$errors)" class="text-red-500">
              {{ v$ErrorMessage(v$.name.$errors) }}
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-6">
            <label for="coach_code">Coach Code</label>
            <div class="p-inputgroup flex-1">
              <InputText
                id="coach-code-input"
                v-model="form.coach_code"
                placeholder="Coach Code"
                :disabled="!isCreateForm"
              />
              <Button
                v-if="!isCreateForm"
                icon="pi pi-copy"
                @click="toggleCopyClipboard"
              />
            </div>
            <Popover
              v-if="!isCreateForm"
              ref="copyClipboardPanelRef"
              class="bg-slate-50 border border-surface-200 dark:border-surface-600 p-6 mt-2 rounded-xl"
              unstyled
            >
              Copied!
            </Popover>
          </div>

          <div class="flex gap-20">
            <div class="flex items-center gap-2 mb-2">
              <ToggleSwitch v-model="form.is_active" />
              <div>Is Active</div>
            </div>
            <div class="flex items-center gap-2 mb-2">
              <ToggleSwitch
                v-model="form.is_private"
                :disabled="!isCreateForm"
              />
              <div>Is Private</div>
            </div>
            <div class="flex items-center gap-2 mb-2">
              <ToggleSwitch v-model="form.payments_enabled" />
              <div>Has Payments</div>
            </div>
          </div>
        </div>
      </div>
    </AppCard>

    <form v-if="!isCreateForm">
      <h3 class="text-lg font-semibold mb-6">Payment Details</h3>
      <AppCard class="mb-6 animate-fadein animate-duration-1000">
        <div class="space-y-4">
          <label
            for="payments-entry-name"
            class="block text-md font-medium text-gray-700"
            >Payments Entry Name</label
          >
          <InputText
            id="payments-entry-name"
            v-model="paymentForm.payabli_entry_name"
            class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            type="text"
            placeholder="Payments Entry Name"
          />
          <label
            for="payments-subdomain"
            class="block text-md font-medium text-gray-700"
            >Payments Subdomain</label
          >
          <InputText
            id="payments-subdomain"
            v-model="paymentForm.payabli_subdomain"
            class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            type="text"
            placeholder="Payments Subdomain"
          />
          <label
            for="payabli-api-token"
            class="block text-md font-medium text-gray-700"
            >Payabli API Token</label
          >
          <div class="p-inputgroup flex">
            <InputText
              id="payabli-api-token"
              v-model="paymentForm.payabli_api_key"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="Payabli API Token"
            />
          </div>

          <label
            for="payabli-api-token-expires-at"
            class="block text-md font-medium text-gray-700"
            >Payabli API Token Expiration Date</label
          >
          <div class="p-inputgroup flex">
            <InputText
              id="payabli-api-token-expires-at"
              v-model="paymentForm.payabli_api_key_expires_at"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="YYYY-MM-DD"
            />
          </div>

          <label
            for="payabli-api-token"
            class="block text-md font-medium text-gray-700"
            >Public Payabli API Token:
          </label>
          <div class="p-inputgroup flex">
            <InputText
              id="public-payabli-api-token"
              v-model="paymentForm.public_payabli_api_key"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="Public Payabli API Token"
            />
          </div>

          <label
            for="payabli-api-token-expires-at"
            class="block text-md font-medium text-gray-700"
            >Public Payabli API Token Expiration Date</label
          >
          <div class="p-inputgroup flex">
            <InputText
              id="public-payabli-api-token-expires-at"
              v-model="paymentForm.public_payabli_api_key_expires_at"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              placeholder="YYYY-MM-DD"
            />
          </div>
          <div class="flex pt-2">
            <ToggleSwitch
              id="payer-pays-fees"
              v-model="paymentForm.payer_pays_fees"
            />
            <label for="payer-pays-fees" class="ml-4">Payer Pays Fees</label>
          </div>
          <div class="flex pt-2">
            <ToggleSwitch
              id="allow-ach-payments"
              v-model="paymentForm.allow_ach_payments"
            />
            <label for="allow-ach-payments" class="ml-4"
              >Allow ACH Payments</label
            >
          </div>
        </div>
      </AppCard>
    </form>
  </form>

  <AppCard
    v-if="!isCreateForm && validOrgId"
    class="animate-fadein animate-duration-1000"
  >
    <Tabs value="0">
      <TabList>
        <Tab value="0">Teams</Tab>
        <Tab value="1">Coaches</Tab>
      </TabList>
      <TabPanels>
        <TabPanel header="Teams" value="0">
          <div class="flex justify-end flex-wrap">
            <Button
              label="Create Team"
              class="mb-6 flex items-center justify-center"
              @click="
                async () => {
                  await router.push({
                    name: ROUTE_NAME.TEAM_CREATE,
                    params: { id: orgId },
                  });
                }
              "
            />
          </div>

          <DataTable
            v-if="orgTeams.length"
            :value="orgTeams"
            striped-rows
            row-hover
            selection-mode="single"
            @row-click="
              (e) => {
                router.push({
                  name: ROUTE_NAME.TEAM_UPDATE,
                  params: { teamId: e?.data?.id, id: e?.data?.organization },
                });
              }
            "
          >
            <Column field="name" header="Name">
              <template #body="{ data }">
                <div>
                  {{ data.name }}
                </div>
              </template>
            </Column>

            <Column field="code.code" header="Player code" />
          </DataTable>

          <Paginator
            :always-show="true"
            :rows="resultsPerPage"
            :total-records="totalTeamRecords"
            :first="firstTeam"
            template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
            current-page-report-template="Showing {first} to {last} of {totalRecords}"
            @page="onPageChangeTeams"
          />
        </TabPanel>
        <TabPanel header="Coaches" value="1">
          <div v-if="form?.coach_code" class="flex justify-center pt-1 pb-12">
            Coach Code: {{ form?.coach_code }}
          </div>

          <DataTable
            :value="orgCoaches"
            striped-rows
            row-hover
            selection-mode="single"
            @row-click="
              (e) => {
                router.push({
                  name: ROUTE_NAME.USER_UPDATE,
                  params: { id: e?.data?.profile?.id },
                });
              }
            "
          >
            <Column field="profile.name" header="Name" />

            <Column field="profile.email" header="Email" />
            <Column header="Is Available">
              <template #body="{ data }">
                <div v-if="data.is_available">Yes</div>
                <div v-else>No</div>
              </template>
            </Column>
            <Column header="Remove">
              <template #body="{ data }">
                <Button
                  icon="pi pi-times"
                  severity="danger"
                  text
                  rounded
                  aria-label="Cancel"
                  class="flex items-center justify-center"
                  @click="confirmDeleteCoach(data.id)"
                />
              </template>
            </Column>
          </DataTable>

          <Paginator
            :always-show="true"
            :rows="resultsPerPage"
            :total-records="totalCoachesRecords"
            :first="firstCoach"
            template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
            current-page-report-template="Showing {first} to {last} of {totalRecords}"
            @page="onPageChangeCoaches"
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </AppCard>

  <AppCard v-if="!validOrgId && !isCreateForm" class="mb-6">
    <div>Organization not found.</div>
  </AppCard>

  <AppCard v-if="isCreateForm && !isStaff" class="mb-6">
    <div>You cannot do that.</div>
  </AppCard>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import InputText from "primevue/inputtext";
import Popover from "primevue/popover";
import ToggleSwitch from "primevue/toggleswitch";
import Tabs from "primevue/tabs";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabList from "primevue/tablist";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import SingleImageUploaderAndDisplay from "@/shared/components/SingleImageUploaderAndDisplay.vue";
import { allowedImageFileExtensionsByKey } from "@/shared/constants/uploader";
import FixedActionBar from "@/shared/components/FixedActionBar.vue";
import OrganizationImportTfaDialog from "./OrganizationImportTfaDialog.vue";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { v$ErrorMessage } from "@/shared/utils/helpers";
import { computed, onMounted, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import AppCard from "@/shared/components/AppCard.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  createOrganization,
  updateOrganization,
  deleteOrganization,
  deleteCoach,
  getTeams,
  getCoaches,
} from "@/modules/organizations/api";
import { usePaymentSettings } from "@/composables/usePaymentSettings";
import { storeToRefs } from "pinia";
import { useOrganizationStore } from "@/modules/organizations/stores/organizationsStore";
import { useAuthStore } from "@/modules/auth/stores/auth";
import type { AdminAsset } from "@/api/model";

const emit = defineEmits(["organization"]);

const route = useRoute();
const orgId = ref(parseInt(route.params.id || 0));

const organizationStore = useOrganizationStore();
const { hasOrganizationError, organizationData } =
  storeToRefs(organizationStore);

const {
  paymentSettings,
  paymentSettingsError,
  fetchPaymentSettings,
  savePaymentSettings,
} = usePaymentSettings(orgId.value);

const { isStaff } = useAuthStore();

const copyClipboardPanelRef = ref();
const toggleCopyClipboard = async (event) => {
  try {
    copyClipboardPanelRef.value.toggle(event);
    await navigator.clipboard.writeText(form.coach_code);
    setTimeout(() => {
      copyClipboardPanelRef.value.toggle(event);
    }, 500);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};
const toast = useToast();
const showToast = (data: object) => {
  toast.add({
    severity: data?.severity ?? "error",
    summary: data?.summary ?? "Error",
    detail: data?.msg,
    life: data?.life ?? 10000,
  });
};

const confirm = useConfirm();
const confirmDeleteOrganization = (orgId: number) => {
  confirm.require({
    message: "Are you sure you want to delete this organization?",
    header: "Delete Organization",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteOrganization(orgId.value);
      } catch (e) {}
    },
  });
};

const confirmDeleteCoach = (coachId: number) => {
  confirm.require({
    message:
      "Are you sure you want to delete this coach from this organization?",
    header: "Delete Coach",
    icon: "icon-delete",
    rejectLabel: "Cancel",
    acceptLabel: "Remove",
    acceptClass: "p-button-danger",
    accept: async () => {
      try {
        await deleteCoachThenRedisplay(coachId);
      } catch (e) {}
    },
  });
};

let logoImageData = ref();

const uploadLogoImageComplete = async ({
  assetId,
  asset,
}: {
  assetId: number;
  asset?: AdminAsset;
}) => {
  if (!assetId || !orgId.value) {
    return;
  }

  const results = await updateOrganization(orgId.value, { logo_id: assetId });

  if ("error" in results) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: results?.error,
      life: 10000,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Image updated.",
      life: 3000,
    });

    // load asset into component
    logoImageData.value = results?.logo;
  }
};

const uploadImageFailed = (d: { error: string }) => {
  if (d.error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: d.error,
      life: 10000,
    });
  }
};

interface uploadLogoImageDeleteType {
  assetId: number;
}

const uploadLogoImageDelete = async (d: uploadLogoImageDeleteType) => {
  if (!orgId.value) {
    return;
  }

  const deletedAsset = await updateOrganization(orgId, { logo_id: null });
  if ("error" in deletedAsset) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: deletedAsset.error,
      life: 10000,
    });
  } else {
    logoImageData.value = undefined;

    toast.add({
      severity: "success",
      summary: "Image removed.",
      life: 3000,
    });
  }
};

const isSubmitting = ref(false);

const orgTeams = ref([]);
const orgCoaches = ref([]);

const isCreateForm = ref(!orgId.value);

let resultsPerPage = 10;
let paginationTeamNum = ref(parseInt(route?.query?.page || 1));
let paginationCoachNum = ref(parseInt(route?.query?.page || 1));

let totalTeamRecords = ref(0);
let totalCoachesRecords = ref(0);
const isCopyAllowed = computed(() => {
  // Only show for staff only
  // Not when creating new org
  // This option doesn't apply for the TFA org
  return (
    isStaff &&
    form.is_private &&
    !isCreateForm.value &&
    form.name.toLowerCase() !== "tfa"
  );
});
const firstTeam = computed(
  () => paginationTeamNum.value * resultsPerPage - resultsPerPage
);

const firstCoach = computed(
  () => paginationCoachNum.value * resultsPerPage - resultsPerPage
);

const validOrgId = ref(true);

const onPageChangeTeams = async (e: any) => {
  paginationTeamNum.value = e?.page + 1;

  router.push({
    query: {
      page: paginationTeamNum.value ?? undefined,
    },
  });

  if (orgId.value) {
    const teams = await getTeams(orgId.value, resultsPerPage, firstTeam.value);
    if ("error" in teams) {
      showToast({ msg: teams.error });
    } else {
      orgTeams.value = teams?.results || [];
      totalTeamRecords.value = teams?.count || 0;
    }
  }
};

const onPageChangeCoaches = async (e: any) => {
  paginationCoachNum.value = e?.page + 1;

  router.push({
    query: {
      page: paginationCoachNum.value ?? undefined,
    },
  });

  if (orgId.value) {
    const coaches = await getCoaches(
      orgId.value,
      resultsPerPage,
      firstCoach.value
    );
    if ("error" in coaches) {
      showToast({ msg: coaches?.error });
    } else {
      orgCoaches.value = coaches?.results || [];
      totalCoachesRecords.value = coaches?.count || 0;
    }
  }
};

interface FormType {
  name: string;
  coach_code: string;
  is_active: boolean;
  is_private: boolean;
  payments_enabled: boolean;
}

const form: FormType = reactive({
  name: "",
  coach_code: "",
  is_active: isCreateForm.value ? true : false,
  is_private: true,
  payments_enabled: false,
});

interface PaymentFormType {
  payabli_api_key: string;
  payabli_api_key_expires_at: string | null;
  public_payabli_api_key: string;
  public_payabli_api_key_expires_at: string | null;
  payabli_entry_name: string;
  payabli_subdomain: string;
  payer_pays_fees: boolean;
  allow_ach_payments: boolean;
}

const paymentForm: PaymentFormType = reactive({
  payabli_api_key: "",
  payabli_api_key_expires_at: "",
  public_payabli_api_key: "",
  public_payabli_api_key_expires_at: "",
  payabli_subdomain: "",
  payabli_entry_name: "",
  payer_pays_fees: false,
  allow_ach_payments: true,
});

const rules = computed(() => ({
  name: { required },
}));
const v$ = useVuelidate(rules, form);

let teamForm = reactive({
  teamName: "",
});
const teamRules = computed(() => ({
  teamName: { required },
}));
const teamv$ = useVuelidate(teamRules, teamForm);

const createOrganizationThenRedisplay = async (f: FormType) => {
  const result = await createOrganization({
    ...f,
    custom_code: f.coach_code,
  });

  if ("error" in result) {
    showToast({ msg: result.error });
    return;
  }

  if (result?.id) {
    await setOrganization();

    router.push({
      name: ROUTE_NAME.ORGANIZATION_UPDATE,
      params: { id: result.id },
    });

    showToast({
      severity: "success",
      msg: "Created organization.",
      summary: "Success",
    });
  }
};

const updateOrganizationThenRedisplay = async (
  orgId: number,
  form: FormType,
  paymentForm: PaymentFormType
) => {
  const result = await updateOrganization(orgId, form);

  if ("error" in result) {
    showToast({ msg: result?.error });
  } else {
    const paymentSettingFormValues = {
      payabli_api_key: paymentForm.payabli_api_key,
      payabli_api_key_expires_at:
        paymentForm.payabli_api_key_expires_at || null,
      public_payabli_api_key: paymentForm.public_payabli_api_key,
      public_payabli_api_key_expires_at:
        paymentForm.public_payabli_api_key_expires_at || null,

      payabli_entry_name: paymentForm.payabli_entry_name,
      payabli_subdomain: paymentForm.payabli_subdomain,
      id: paymentSettings.value?.id,
      payer_pays_fees: paymentForm.payer_pays_fees,
      allow_ach_payments: paymentForm.allow_ach_payments,
    };
    await savePaymentSettings(paymentSettingFormValues);

    if (paymentSettingsError.value) {
      showToast({ msg: paymentSettingsError.value });
    } else {
      await setOrganization();

      showToast({
        severity: "success",
        msg: "Updated organization.",
        summary: "Success",
      });
    }
  }
};

const deleteCoachThenRedisplay = async (coachId: number) => {
  const result = await deleteCoach(coachId);
  if (result?.error) {
    showToast({ msg: result.error });
  } else {
    await setOrganization();
    showToast({
      severity: "success",
      msg: "Coach deleted",
      summary: "Success",
    });
  }
};

let canWrite = ref(false);
let canRead = ref(false);

const setOrganization = async () => {
  if (isStaff) {
    canWrite.value = true;
    canRead.value = true;
  } else {
    canWrite.value = false;
    canRead.value = true;
  }

  if (orgId.value) {
    await organizationStore.fetchOrganization(
      orgId.value,
      isStaff,
      resultsPerPage,
      firstTeam.value
    );

    logoImageData.value = organizationData.value?.logo || null;

    if (hasOrganizationError.value) {
      showToast({ msg: hasOrganizationError.value });
      validOrgId.value = false;
    } else {
      validOrgId.value = true;

      if (canWrite.value && validOrgId.value) {
        await fetchPaymentSettings();
      }

      if (paymentSettings.value) {
        paymentForm.payabli_entry_name =
          paymentSettings.value.payabli_entry_name;

        paymentForm.payabli_api_key = paymentSettings.value.payabli_api_key;
        paymentForm.payabli_api_key_expires_at =
          paymentSettings.value.payabli_api_key_expires_at || null;

        paymentForm.public_payabli_api_key =
          paymentSettings.value.public_payabli_api_key || "";
        paymentForm.public_payabli_api_key_expires_at =
          paymentSettings.value.public_payabli_api_key_expires_at || null;

        paymentForm.public_payabli_api_key_expires_at =
          paymentSettings.value.public_payabli_api_key_expires_at ?? "";
        paymentForm.payabli_subdomain =
          paymentSettings.value.payabli_subdomain ?? "";
        paymentForm.payer_pays_fees =
          paymentSettings.value.payer_pays_fees ?? false;
        paymentForm.allow_ach_payments =
          paymentSettings.value.allow_ach_payments ?? true;
      }

      form.name = organizationData.value?.name || "";
      form.coach_code = (organizationData.value?.code || {})?.code || "";
      form.is_active = organizationData.value?.is_active || false;
      form.is_private = organizationData.value?.is_private || false;
      form.payments_enabled = organizationData.value?.payments_enabled || false;

      orgTeams.value = organizationData.value?.teams?.results || [];
      totalTeamRecords.value = organizationData.value?.teams?.count || 0;

      orgCoaches.value = organizationData.value?.coaches?.results || [];
      totalCoachesRecords.value = organizationData.value?.coaches?.count || 0;

      emit("organization", { organizationName: form?.name });
    }
  } else {
    validOrgId.value = false;
  }
};

onMounted(async () => {
  await setOrganization();
});
</script>
