/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

export type AdminV1PaymentsStatisticsListFreq = typeof AdminV1PaymentsStatisticsListFreq[keyof typeof AdminV1PaymentsStatisticsListFreq];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminV1PaymentsStatisticsListFreq = {
  d: 'd',
  h: 'h',
  m: 'm',
  w: 'w',
} as const;
