import {
  adminV1PaymentsSettingsList,
  adminV1PaymentsSettingsCreate,
  adminV1PaymentsSettingsRetrieve,
  adminV1PaymentsSettingsUpdate,
} from "@/api/admin-organization-payment-settings/admin-organization-payment-settings";

import type { AdminOrganizationPaymentSettings } from "@/api/model/adminOrganizationPaymentSettings";
import type { ApiError, OrganizationPaymentAdminFields } from "@/types";

export const createOrganizationPaymentSettings = async (
  data: OrganizationPaymentAdminFields
): Promise<OrganizationPaymentAdminFields | ApiError> => {
  const convertedData = convertForOrval(data);
  const organizationPaymentSettings = await adminV1PaymentsSettingsCreate({
    ...convertedData,
  });

  return convertForVue(organizationPaymentSettings);
};

export const getOrganizationPaymentSettings = async (
  id: number
): Promise<OrganizationPaymentAdminFields | ApiError> => {
  const organizationPaymentSettings = await adminV1PaymentsSettingsRetrieve(id);

  return convertForVue(organizationPaymentSettings);
};

export const updateOrganizationPaymentSettings = async (
  id: number,
  data: OrganizationPaymentAdminFields
): Promise<OrganizationPaymentAdminFields | ApiError> => {
  const convertedData = convertForOrval(data);
  const organizationPaymentSettings = await adminV1PaymentsSettingsUpdate(id, {
    ...convertedData,
  });

  return convertForVue(organizationPaymentSettings);
};

export const listOrganizationPaymentSettings = async (
  params?: any
): Promise<OrganizationPaymentAdminFields | ApiError> => {
  const organizationPaymentSettings = await adminV1PaymentsSettingsList(params);
  if (
    !organizationPaymentSettings.results ||
    organizationPaymentSettings.results!.length === 0
  ) {
    return {
      id: null,
      paymentAdminEmail: "",
      paymentAdminName: "",
      paymentAdminPhone: "",
    };
  }

  return convertForVue(organizationPaymentSettings.results![0]);
};

const convertForVue = (data: AdminOrganizationPaymentSettings) => {
  return {
    id: data.id,
    paymentAdminEmail: data.payment_admin_email,
    paymentAdminName: data.payment_admin_name,
    paymentAdminPhone: data.payment_admin_phone,
  };
};

const convertForOrval = (data: OrganizationPaymentAdminFields) => {
  return {
    id: data.id || null,

    payment_admin_email: data.paymentAdminEmail,
    payment_admin_name: data.paymentAdminName,
    payment_admin_phone: data.paymentAdminPhone,
  };
};
