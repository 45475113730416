import { ref, type Ref } from "vue";

import { getInvoices } from "../api";
import type {
  AdminV1PaymentsInvoicesListParams,
  PaginatedInvoiceListList,
} from "@/api/model";
import type { InvoiceFilters } from "@/types";

const PAGE_SIZE = 20;
const FIRST_PAGE = 0;

export function useInvoices(): {
  paginatedInvoices: Ref<PaginatedInvoiceListList | null>;
  fetchInvoices: () => Promise<void>;
  invoiceFetchError: Ref<string | null>;
  currentPage: Ref<number>;
  pageSize: Ref<number>;
  totalRecords: Ref<number | undefined>;
  filter: Ref<InvoiceFilters | null>;
} {
  const pageSize = ref(PAGE_SIZE);
  const currentPage = ref(FIRST_PAGE);
  const totalRecords = ref<number | undefined>(0);
  const filter: Ref<InvoiceFilters | null> = ref<InvoiceFilters | null>(null);

  const invoiceFetchError = ref<string | null>(null);
  const paginatedInvoices = ref<PaginatedInvoiceListList | null>(null);

  async function fetchInvoices() {
    const offset = currentPage.value * pageSize.value;
    const params: AdminV1PaymentsInvoicesListParams = {};

    params.limit = pageSize.value;
    params.offset = offset;

    if (filter.value && filter.value !== null) {
      params.filter = filter.value;
    }

    const results = await getInvoices(params);

    if ("error" in results) {
      invoiceFetchError.value = results.error;
    } else {
      paginatedInvoices.value = results;
      totalRecords.value = results.count;
    }
  }

  return {
    paginatedInvoices,
    fetchInvoices,
    invoiceFetchError,
    currentPage,
    pageSize,
    totalRecords,
    filter,
  };
}
