/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */

export type AdminV1PaymentsStatisticsListMode = typeof AdminV1PaymentsStatisticsListMode[keyof typeof AdminV1PaymentsStatisticsListMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminV1PaymentsStatisticsListMode = {
  custom: 'custom',
  d30: 'd30',
  h24: 'h24',
  lastm: 'lastm',
  lastw: 'lastw',
  lasty: 'lasty',
  m12: 'm12',
  mtd: 'mtd',
  today: 'today',
  wtd: 'wtd',
  yesterday: 'yesterday',
  ytd: 'ytd',
} as const;
