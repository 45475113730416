<template>
  <form @submit.prevent="onSave">
    <AppCard class="mb-6">
      <div class="mb-6 px-4">
        <h1 class="text-xl font-bold mb-6">Payment Contact Information</h1>
        <hr class="mb-4" />
        <div class="flex justify-end flex-wrap mb-6">
          <AppButton
            type="submit"
            label="Save"
            aria-label="Save"
            class="mr-2"
          />
        </div>

        <div class="space-y-2">
          <div class="flex items-center space-x-4">
            <label
              for="payment-admin-name"
              class="block w-64 text-md font-medium text-gray-700"
            >
              Full Name
            </label>
            <div class="w-full">
              <InputText
                id="payment-admin-name"
                v-model="paymentAdminForm.paymentAdminName"
                type="text"
                :class="{
                  'border-red-500': v$.paymentAdminForm.paymentAdminName.$error,
                }"
                class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
              />
              <span
                v-if="v$.paymentAdminForm.paymentAdminName.$error"
                class="text-red-500 text-sm"
              >
                {{ v$.paymentAdminForm.paymentAdminName.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <label
              for="payment-admin-email"
              class="block w-64 text-md font-medium text-gray-700"
              >Email Address</label
            >
            <div class="w-full">
              <InputText
                id="payment-admin-email"
                v-model="paymentAdminForm.paymentAdminEmail"
                type="email"
                :class="{
                  'border-red-500':
                    v$.paymentAdminForm.paymentAdminEmail.$error,
                }"
                class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
              />
              <span
                v-if="v$.paymentAdminForm.paymentAdminEmail.$error"
                class="text-red-500 text-sm"
              >
                {{ v$.paymentAdminForm.paymentAdminEmail.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <label
              for="payment-admin-phone"
              class="block w-64 text-md font-medium text-gray-700"
              >Phone Number</label
            >
            <div class="w-full">
              <InputText
                id="payment-admin-phone"
                v-model="paymentAdminForm.paymentAdminPhone"
                type="tel"
                :class="{
                  'border-red-500':
                    v$.paymentAdminForm.paymentAdminPhone.$error,
                }"
                class="mt-1 block w-full rounded-md shadow-sm border-gray-300"
              />
              <span
                v-if="v$.paymentAdminForm.paymentAdminPhone.$error"
                class="text-red-500 text-sm"
              >
                {{ v$.paymentAdminForm.paymentAdminPhone.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  </form>
</template>

<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";
import InputText from "primevue/inputtext";
import { computed, onMounted, ref, type Ref } from "vue";
import {
  createOrganizationPaymentSettings,
  updateOrganizationPaymentSettings,
  listOrganizationPaymentSettings,
} from "@/modules/organizationPaymentSettings/api";
import { useToast } from "primevue/usetoast";
import type { OrganizationPaymentAdminFields } from "@/types";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  email,
  maxLength,
  minLength,
  numeric,
  required,
} from "@vuelidate/validators";

const toast = useToast();

interface paymentAdminFormType {
  id: number | null;
  paymentAdminName: string;
  paymentAdminEmail: string;
  paymentAdminPhone: string;
}

let paymentAdminForm: Ref<paymentAdminFormType> = ref({
  id: null,
  paymentAdminName: "",
  paymentAdminEmail: "",
  paymentAdminPhone: "",
});

const fetchOrganizationPaymentAdmin = async () => {
  return await listOrganizationPaymentSettings();
};

const saveOrganizationPaymentAdmin = async () => {
  let result;
  if (paymentAdminForm.value.id === null) {
    result = await createOrganizationPaymentSettings(paymentAdminForm.value);
  } else {
    result = await updateOrganizationPaymentSettings(
      paymentAdminForm.value.id,
      paymentAdminForm.value
    );
  }
  if (result && "error" in result) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: result.error,
    });
  } else {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Payment settings saved.",
    });
  }
  return;
};

const populateFormData = (data: OrganizationPaymentAdminFields) => {
  paymentAdminForm.value.id = data.id ? data.id : null;
  paymentAdminForm.value.paymentAdminName = data.paymentAdminName;
  paymentAdminForm.value.paymentAdminEmail = data.paymentAdminEmail;
  paymentAdminForm.value.paymentAdminPhone = data.paymentAdminPhone;
};

const rules = computed(() => ({
  paymentAdminForm: {
    paymentAdminName: {
      required: helpers.withMessage("Full name is required.", required),
    },
    paymentAdminEmail: {
      required: helpers.withMessage("An email is required.", required),
      email: helpers.withMessage("Invalid email address.", email),
    },

    paymentAdminPhone: {
      required: helpers.withMessage("Phone number is required.", required),
      numeric: helpers.withMessage(
        "Phone number must contain only numbers.",
        numeric
      ),
      minLength: helpers.withMessage(
        "Phone number must be 10 digits.",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Phone number must be 10 digits.",
        maxLength(10)
      ),
    },
  },
}));

const v$ = useVuelidate(rules, { paymentAdminForm });

const onSave = async () => {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    return;
  }
  await saveOrganizationPaymentAdmin();
};

onMounted(async () => {
  try {
    const data = await fetchOrganizationPaymentAdmin();
    populateFormData(data);
  } catch (error) {
    console.error("Error fetching payment admin data: ", error);
  }
});
</script>
