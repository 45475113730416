/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsStatisticsListParams,
  PaypointStatistics
} from '.././model'
import adminV1PaymentsStatisticsListMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsStatisticsList = (
    params: AdminV1PaymentsStatisticsListParams,
 options?: SecondParameter<typeof adminV1PaymentsStatisticsListMutator>,) => {
      return adminV1PaymentsStatisticsListMutator<PaypointStatistics[]>(
      {url: `/api/admin/v1/payments/statistics/`, method: 'GET',
        params
    },
      options);
    }
  export type AdminV1PaymentsStatisticsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsStatisticsList>>>
