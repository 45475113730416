import { ref, type Ref } from "vue";
import {
  getPaymentSettings,
  createPaymentSettings,
  updatePaymentSettings,
} from "@/modules/organizations/api";
import type { AdminPaymentSetting } from "@/api/model";

export type AdminPaymentSettingUpsertPayload = {
  id?: number;
  payabli_api_key?: string;
  payabli_api_key_expires_at?: string | null;
  payabli_entry_name?: string;
  payabli_subdomain?: string;
  payer_pays_fees?: boolean;
  allow_ach_payments?: boolean;
};

interface UsePaymentSettingsReturn {
  paymentSettings: Ref<AdminPaymentSetting | null>;
  fetchPaymentSettings: () => Promise<void>;
  savePaymentSettings: (
    settings: AdminPaymentSettingUpsertPayload
  ) => Promise<void>;
  paymentSettingsError: Ref<string | null>;
}

export function usePaymentSettings(orgId: number): UsePaymentSettingsReturn {
  const paymentSettings = ref<AdminPaymentSetting | null>(null);
  const paymentSettingsError = ref<string | null>(null);
  paymentSettingsError.value = null;

  const fetchPaymentSettings = async () => {
    if (!orgId) return;

    const response = await getPaymentSettings(orgId);

    if ("error" in response) {
      paymentSettingsError.value = response.error;
    } else {
      paymentSettings.value = response;
    }
  };

  const savePaymentSettings = async (
    settings: AdminPaymentSettingUpsertPayload
  ) => {
    if (!orgId) return;
    paymentSettingsError.value = null;

    let response;

    if (settings.id) {
      response = await updatePaymentSettings(orgId, settings);
    } else {
      response = await createPaymentSettings(orgId, settings);
    }

    if ("error" in response) {
      paymentSettingsError.value = response.error;
    } else {
      paymentSettings.value = response;
    }
  };

  return {
    paymentSettings,
    fetchPaymentSettings,
    savePaymentSettings,
    paymentSettingsError,
  };
}
