import { type RouteRecordRaw } from "vue-router";
import { ROUTE_NAME } from "@/shared/constants/routes";
import OrganizationPaymentSettings from "./views/OrganizationPaymentSettings.vue";

export const routes: RouteRecordRaw[] = [
  {
    path: "/payments/payment-settings",
    name: ROUTE_NAME.ORGANIZATION_PAYMENT_SETTINGS,
    component: OrganizationPaymentSettings,
  },
];
