/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminOrganizationPaymentSettings,
  AdminV1PaymentsSettingsListParams,
  PaginatedAdminOrganizationPaymentSettingsList,
  PatchedAdminOrganizationPaymentSettings
} from '.././model'
import adminV1PaymentsSettingsListMutator from '../../shared/services/axios-client';
import adminV1PaymentsSettingsCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsSettingsRetrieveMutator from '../../shared/services/axios-client';
import adminV1PaymentsSettingsUpdateMutator from '../../shared/services/axios-client';
import adminV1PaymentsSettingsPartialUpdateMutator from '../../shared/services/axios-client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsSettingsList = (
    params?: AdminV1PaymentsSettingsListParams,
 options?: SecondParameter<typeof adminV1PaymentsSettingsListMutator>,) => {
      return adminV1PaymentsSettingsListMutator<PaginatedAdminOrganizationPaymentSettingsList>(
      {url: `/api/admin/v1/payments/settings/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1PaymentsSettingsCreate = (
    adminOrganizationPaymentSettings: NonReadonly<AdminOrganizationPaymentSettings>,
 options?: SecondParameter<typeof adminV1PaymentsSettingsCreateMutator>,) => {
      return adminV1PaymentsSettingsCreateMutator<AdminOrganizationPaymentSettings>(
      {url: `/api/admin/v1/payments/settings/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminOrganizationPaymentSettings
    },
      options);
    }
  export const adminV1PaymentsSettingsRetrieve = (
    id: number,
 options?: SecondParameter<typeof adminV1PaymentsSettingsRetrieveMutator>,) => {
      return adminV1PaymentsSettingsRetrieveMutator<AdminOrganizationPaymentSettings>(
      {url: `/api/admin/v1/payments/settings/${id}/`, method: 'GET'
    },
      options);
    }
  export const adminV1PaymentsSettingsUpdate = (
    id: number,
    adminOrganizationPaymentSettings: NonReadonly<AdminOrganizationPaymentSettings>,
 options?: SecondParameter<typeof adminV1PaymentsSettingsUpdateMutator>,) => {
      return adminV1PaymentsSettingsUpdateMutator<AdminOrganizationPaymentSettings>(
      {url: `/api/admin/v1/payments/settings/${id}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: adminOrganizationPaymentSettings
    },
      options);
    }
  export const adminV1PaymentsSettingsPartialUpdate = (
    id: number,
    patchedAdminOrganizationPaymentSettings: NonReadonly<PatchedAdminOrganizationPaymentSettings>,
 options?: SecondParameter<typeof adminV1PaymentsSettingsPartialUpdateMutator>,) => {
      return adminV1PaymentsSettingsPartialUpdateMutator<AdminOrganizationPaymentSettings>(
      {url: `/api/admin/v1/payments/settings/${id}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedAdminOrganizationPaymentSettings
    },
      options);
    }
  export type AdminV1PaymentsSettingsListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSettingsList>>>
export type AdminV1PaymentsSettingsCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSettingsCreate>>>
export type AdminV1PaymentsSettingsRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSettingsRetrieve>>>
export type AdminV1PaymentsSettingsUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSettingsUpdate>>>
export type AdminV1PaymentsSettingsPartialUpdateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSettingsPartialUpdate>>>
